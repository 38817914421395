<template>
  <div class="global-blue">
    <v-container>
      <v-row justify="center">
        <v-dialog
          v-model="signin"
          max-width="650px"
        >
          <v-card
            class="contact pa-4"
            dark
            @keyup.enter="onSubmit"
          >
            <ValidationObserver v-slot="{ invalid }">
              <v-card-title>
                <v-icon
                  color="green"
                  class="ml-n4 mr-2"
                >mdi-lock-outline</v-icon>
                {{ locale == 'fi' ? "Kirjaudu sisään" : "Login"}}
              </v-card-title>

              <ValidationProvider
                rules="email|required"
                name="username"
              >
                <v-text-field
                  :label="locale == 'fi' ? 'Sähköposti' : 'Email'"
                  type="email"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  :error-messages="errors"
                  :success="valid"
                  v-model="username"
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                rules="required"
                name="username"
              >
                <v-text-field
                  :label="locale == 'fi' ? 'Salasana' : 'Password'"
                  type="password"
                  filled
                  slot-scope="{
                              errors,
                              valid
                            }"
                  :error-messages="errors"
                  :success="valid"
                  v-model="password"
                >
                </v-text-field>
              </ValidationProvider>

              <v-btn small text @click="passwordForgotten">{{ locale == 'fi' ? 'Salasana unohtunut?' : 'Password forgotten?' }}</v-btn>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="close"
                >
                  {{ locale == 'fi' ? "Peruuta" : "Cancel"}}
                </v-btn>
                <v-btn
                  color="green"
                  text
                  :disabled="invalid"
                  @click.prevent="onSubmit"
                >{{ locale == 'fi' ? "Kirjaudu" : "Login"}}</v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.contact {
  background: var(--v-primary-base) !important;
}
</style>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

export default {
  name: "Signin",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      signin: this.show,
      username: "",
      password: ""
    };
  },
  methods: {
    onSubmit() {
      const formData = {
        username: this.username,
        password: this.password
      };
      this.$store.dispatch("login", {
        username: formData.username,
        password: formData.password
      });
    },
    close() {
      this.clearData();
      this.$store.commit("toggleSigninForm", false);
    },
    clearData() {
      (this.username = ""), (this.password = "");
    },
    passwordForgotten() {
      window.open(`${process.env.VUE_APP_AUTH_URL}/#/?login&reset=true`, `_blank`)
    }
  },
  watch: {
    signin(val) {
      if (val == false && this.show == true) {
        this.clearData();
        this.$store.commit("toggleSigninForm", false);
      }
    },
    show(val) {
      this.clearData();
      this.signin = val;
    },
    loginStatus(val) {
      if (val) {
        this.$store.commit("toggleSigninForm", false);
      }
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    loginStatus() {
      return this.$store.state.loginStatus;
    },
    show() {
      return this.$store.state.signinForm;
    }
  },
  created() {
    extend("email", {
      ...email,
      message:
        this.locale == "fi"
          ? 'Sähköpostiosoitteen tulee olla muotoa "esimerkki@esimerkki.fi"'
          : "Valid email is required"
    });

    extend("required", {
      ...required,
      message:
        this.locale == "fi"
          ? "Tämä tieto vaaditaan pyynnön välittämiseksi"
          : "This field is required"
    });
  }
};
</script>
